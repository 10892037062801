



.firebase-emulator-warning {
    display: none
  }


ion-tab-bar {
  /* border-radius: 16px 16px 0 0 ;
  --border-width: 0 0 0 0; */
}

.no-padding {
  padding: 0
}