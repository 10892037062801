.login-search {
    --placeholder-color: black
    --placeholder-font-size: 10px;
    font-weight: bold;
    font-family: 'Poppins';

  }

.login-button {
    padding: 0 15px 0 15px;
    
}

.login-error {
    font-size: small;
}

  .signup-search {
    --placeholder-color: black
    --placeholder-font-size: 10px;
    font-weight: bolder

  }
  
.floating-label {
    opacity: 40%;
}


ion-item {
    --border-color: rgb(241, 241, 241); 
}

.date-picker {
    text-align: -webkit-center
}


.success-circle {
    text-align: -webkit-center;
    margin-top: 30px;
}

.success-circle div {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #44ACAD;
    opacity: 50%;
    margin-top: 70px;
    
}

.success-circle div {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #44ACAD;
    opacity: 50%;
    margin-top: 70px;
    
}

.sucess-text {
    font-size: 30px;
    font-weight: bold;
}

.success-button {
    margin-top: 150px;
}