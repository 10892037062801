#calendarPopup {
    --width: 400px;
    --min-width: 332px
}

.dRangeDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}