.tableHeader {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12)
}

.tableRow {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12)
}

.tableCell {
    display: flex;
    align-items: center
}

.pagination {
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}

.rdrDayToday .rdrDayNumber span:after {
    background: #0000
}

.react-datepicker .rdrInRange {
    color: #0000
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #a66;
    color: #111;
    border: 0px;
    border-radius: 0;
    display: inline-block;
    position: relative;
}

.rdrEndEdge {
    color: #0000
}