.menu-user-avatar-div {
  text-align: -webkit-center;
  padding-top: 10px;
}
.menu-user-avatar {
  width: 100px;
  height: 100px;
}

.menu-list {
    font-family: 'Poppins';
    margin-top: 20px;
}

.menu-item {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 100%
}

.menu-item ion-icon {
    margin-right: 10px;
    color: var(--ion-color-primary);
    
}
.item-detail-icon {
    margin-right: 10px;
    color: var(--ion-color-primary) !important;
}

.menu-item .menu-arrow {
    margin-right: 0;
    color: var(--ion-color-primary);
}

.menu-item-logout {
    position: absolute;
    bottom: 0;
}
